var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"invoice-list"}},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"footer-props":_vm.footerProps,"items":_vm.invoiceListTable,"options":_vm.options,"server-items-length":_vm.totalInvoiceListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ path: ("/moloni/invoices/" + (item.document_id)) }}},[_vm._v(" #"+_vm._s(item.document_set_name)+"/"+_vm._s(item.number)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({class:("v-avatar-light-bg " + (_vm.resolveInvoiceStatusVariantAndIcon(item).variant) + "--text"),attrs:{"size":"30","color":_vm.resolveInvoiceStatusVariantAndIcon(item).variant}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveInvoiceStatusVariantAndIcon(item).variant}},[_vm._v(" "+_vm._s(_vm.resolveInvoiceStatusVariantAndIcon(item).icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTrad(_vm.text)[_vm.resolveInvoiceStatusVariantAndIcon(item).text]))])])]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveClientAvatarVariant(item)) + "--text"),attrs:{"color":_vm.resolveClientAvatarVariant(item),"size":"30"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.entity_name)))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.entity_name))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.entity_vat))])])],1)]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.net_value)+"€")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(_vm.checkType(item.reconciled_value) !== 'number')?_c('span',[_vm._v(" "+_vm._s(item.reconciled_value)+" ")]):_c('v-chip',{class:("v-chip-light-bg " + (item.net_value == item.reconciled_value ? 'success' : 'error') + "--text font-weight-semibold"),attrs:{"small":"","color":item.net_value == item.reconciled_value ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.net_value == item.reconciled_value ? _vm.getTrad(_vm.text).paid : _vm.getTrad(_vm.text).unpaid)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$moment(item.date).format('DD-MM-YYYY')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ path: ("/moloni/invoices/" + (item.document_id)) }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTrad(_vm.text).see))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","disabled":item.status == 0},on:{"click":function($event){return _vm.downloadInvoice(item.document_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTrad(_vm.text).download))])])],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }